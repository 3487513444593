$(document).ready(function(){
	$(window).load(function(){
		AOS.init();
		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		iOS_CaretBug();
		responsiveSlick();
		cf7formsubmit();
		tableClass();
		stickySideBar();
		objLightcase();
		inquiry_modal()
		slickGallery();
		smoothScrollTo();
		setTimeout(explode, 3000);
		setTimeout(slickMenu, 500);
		$(window).on('resize', function(){});
		
	});
});

function inquiry_modal() {
	if( $('.single-product-template').is(':visible') ) {
		$('.single-product-template').on('click', '.inquire-now', function(e) {
			e.preventDefault();
			var serv_title = $(this).data('title');
			$('#serv-name').val(serv_title);
			$('#inqserv').html('<span class="bold">PRODUCT:</span>' + ' ' + serv_title);
			$('#inquiry-modal').modal('show');
		});
	}
}
function explode(){
 
}

           
             function slickMenu(){
                $('.slick-responsive2').each(function(){
                    var breaks = $(this).data('breakpoints').split('|'),
                    breakArr = [],
                    arrowsval,
                    varWidth,
                    varInfinite;

                    $.each(breaks, function(i, items){
                        breakArr.push({
                            breakpoint: parseInt(items.split(',')[0]),
                      settings: {
                        slidesToShow: parseInt(items.split(',')[1])
                      }
                        });
                    });
                    if( $(this).data('arrows') ) {
                        arrowsval = true;
                    } else {
                        arrowsval = false;
                    }
                    if( $(this).data('vwidth') ) {
                        varWidth = true;
                    } else {
                        varWidth = false;
                    }
                    if( $(this).data('infinite') ) {
                        varInfinite = true;
                    } else {
                        varInfinite = false;
                    }
                    $(this).slick({
                      dots: false,
                      infinite: varInfinite,
                      speed: 300,
                      autoplay: false,
                      arrows: arrowsval,
                      slidesToShow: $(this).data('defaultview'),
                      slidesToScroll: $(this).data('slidescroll'),
                      adaptiveHeight: true,
                      variableWidth: varWidth,
                      asNavFor: $(this).data('thumb'),
                      responsive : breakArr,
                      prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
                      nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
                    });
                });
            }


// IOS INPUT BUG FIX
function iOS_CaretBug() {
	var ua = navigator.userAgent,
	scrollTopPosition,
	iOS = /iPad|iPhone|iPod/.test(ua),
	iOS11 = /OS 11_0|OS 11_1|OS 11_2/.test(ua);

	// ios 11 bug caret position
 	if ( iOS && iOS11 ) {

		$(document.body).on('show.bs.modal', function(e) {
			if ( $(e.target).hasClass('modal') ) {
				// Get scroll position before moving top
				scrollTopPosition = $(document).scrollTop();

				// Add CSS to body "position: fixed"
				$("body").addClass("iosBugFixCaret");
			}
		});

     $(document.body).on('hide.bs.modal', function(e) {
			if ( $(e.target).hasClass('modal') ) {
				// Remove CSS to body "position: fixed"
				$("body").removeClass("iosBugFixCaret");

				//Go back to initial position in document
				$(document).scrollTop(scrollTopPosition);
			}
     });
   }
}

function tableClass(){
	var $tables = $(document).find('table');
	if( $tables ) {
		$tables.wrap('<div class="table-responsive"></div>');
		$tables.addClass('table');
	}
}

function smoothScrollTo() {
// Add smooth scrolling to all links
	  $("a").on('click', function(event) {

	    // Make sure this.hash has a value before overriding default behavior
	    if (this.hash !== "") {
	      // Prevent default anchor click behavior
	      event.preventDefault();

	      // Store hash
	      var hash = this.hash;

	      // Using jQuery's animate() method to add smooth page scroll
	      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
	      $('html, body').animate({
	        scrollTop: $(hash).offset().top
	      }, 800, function(){

	        // Add hash (#) to URL when done scrolling (default click behavior)
	        window.location.hash = hash;
	      });
	    } // End if
	  });
}

// Plugins
function objLightcase(){
	$('a[data-rel^=lightcase]').lightcase();
}

function responsiveSlick(){
	$('.slick-responsive').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval,
		varWidth,
		varInfinite;

		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		if( $(this).data('vwidth') ) {
			varWidth = true;
		} else {
			varWidth = false;
		}
		if( $(this).data('infinite') ) {
			varInfinite = true;
		} else {
			varInfinite = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: varInfinite,
		  speed: 300,
		  autoplay: false,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: $(this).data('slidescroll'),
		  adaptiveHeight: true,
		  variableWidth: varWidth,
		  asNavFor: $(this).data('thumb'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});
}

function cf7formsubmit(){
	
	$('.wpcf7-form').each(function(){
		var form = $(this);
		form.find(".wpcf7-submit, .ajax-loader").wrapAll('<div class="wcf7-wrap-btn"></div>');
		setTimeout(function(){
			var wdSubmit = form.find(".wpcf7-submit").outerWidth();
			form.find('.wcf7-wrap-btn').css({
				'width' : wdSubmit
			});
		}, 100);
	});

	$(document).on('click', '.wpcf7-submit', function(){
		var $btn = $(this);
	  var $ajaxLoader = $('.ajax-loader');
	  
	  $btn.addClass('loading');
	  $ajaxLoader.addClass('visible');

		var sitelink = $('.usd').data('usdacct');
		document.addEventListener( 'wpcf7mailsent', function( event ) {
		  location = sitelink + '/thank-you/';
		}, false );
		document.addEventListener( 'wpcf7invalid', function( event ) {
			$btn.removeClass('loading');
		 	$ajaxLoader.removeClass('visible');
		}, false );
	});
}

function carouselAnimation(){
	/* Demo Scripts for Bootstrap Carousel and Animate.css article
	* on SitePoint by Maria Antonietta Perna
	*/
  //Function to animate slider captions 
  function doAnimations( elems ) {
    //Cache the animationend event in a variable
    var animEndEv = 'webkitAnimationEnd animationend';
    
    elems.each(function () {
      var $this = $(this),
        $animationType = $this.data('animation');
      $this.addClass($animationType).one(animEndEv, function () {
        $this.removeClass($animationType);
      });
    });
  }
  
  //Variables on page load 
  var $myCarousel = $('.carousel'),
    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");
      
  //Initialize carousel 
  $myCarousel.carousel();
  
  //Animate captions in first slide on page load 
  doAnimations($firstAnimatingElems);
  
  //Pause carousel  
  $myCarousel.carousel('pause');
  
  
  //Other slides to be animated on carousel slide event 
  $myCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    doAnimations($animatingElems);
  });

  $('.carousel').carousel();
}



/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
function screenBpoints(){
	if( Modernizr.mq('(min-width: 1200px)') ) {
	}
	else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
	}
	else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
	}
	else{
	}
}

function stickySideBar(){
	var $navHeight = $('header.site-header').outerHeight();
	if( Modernizr.mq('(min-width: 768px)') ) {
		$('.sticky-bar').each(function(){
			$(this).stickySidebar({
				topSpacing: $navHeight + 30
			});
		});
	}
}


function slickGallery(){

  if( $('.slick-gallery-nav').length > 0 ) {

    var galleryBreaks = $('.slick-gallery-nav').data('breakpoints').split('|'),
      galleryBreakArr = [],
      galleryView = $('.slick-gallery-nav').data('view'),
      galleryArrowsval;

    $.each(galleryBreaks, function(i, items){
      galleryBreakArr.push({
        breakpoint: parseInt(items.split(',')[0]),
        settings: {
          slidesToShow: parseInt(items.split(',')[1])
        }
      });
    });

    if( $('.slick-gallery-nav').data('arrows') ) {
      galleryArrowsval = true;
    } else {
      galleryArrowsval = false;
    }

    $('.slick-gallery-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      infinite: false,
      asNavFor: '.slick-gallery-nav',
       prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
      nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
    });

    $('.slick-gallery-nav').slick({
      slidesToShow: galleryView,
      slidesToScroll: 1,
      asNavFor: '.slick-gallery-for',
      arrows: galleryArrowsval,
      focusOnSelect: true,
      infinite: false,
      responsive : galleryBreakArr,
      prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
      nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
    });

  }
}



